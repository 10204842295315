import { CreateQuizTeam as createQuizTeam} from "../../../interfaces/QuizTeam/CreateQuizTeam";
import { QuizTeam } from "../../../interfaces/QuizTeam/QuizTeam";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import { UpdateQuizTeam as updateQuizTeam } from "../../../interfaces/QuizTeam/UpdateQuizTeam";
import { getApiClient } from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";

export class QuizTeamRepo {
  private readonly apiClient: AxiosInstance;

  constructor(auth: AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

  async RemoveQuizTeam(callback: () => void, quizId: number, quizTeamId: number) {
    await this.apiClient.delete(`/quiz/${quizId}/team/${quizTeamId}`)
      .then((res: any) => {
        callback();
      });
  }

  async UpdateQuizTeam(callback: () => void, quizId: number, quizTeamId: number, updateQuizTeam: updateQuizTeam): Promise<void> {
    await this.apiClient.put(`/quiz/${quizId}/team/${quizTeamId}`, updateQuizTeam)
      .then((res: any) => {
        callback();
      });
  }

  async CreateQuizTeam(callback: (quizTeam: QuizTeam) => void, quizId: number, createQuizTeam: createQuizTeam) {
    await this.apiClient.post(`/quiz/${quizId}/team/`, createQuizTeam)
      .then((res: any) => {
        let quizTeamId = res.data;
        callback(quizTeamId);
      });
  }

  async GetQuizTeams(callback: (data: QuizTeamWithQuizPlayers[]) => void, quizId: number, includePlayers: boolean, includeUnassignedTeam: boolean): Promise<void> {
    await this.apiClient.get(`/quiz/${quizId}/team?includePlayers=${includePlayers}&includeUnassignedTeam=${includeUnassignedTeam}`)
      .then((res: any) => {
        let data = res.data as QuizTeamWithQuizPlayers[];
        callback(data);
      });
  }
}

