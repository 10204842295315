import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { Round } from "../../../interfaces/Quiz/Round";

export function UpdateQuizWithNewRound(quiz: Quiz, newRound: Round): Quiz {
  let copyOfRounds = [...quiz.rounds];
  copyOfRounds.push(newRound);

  const updatedQuiz: Quiz = {
    ...quiz,
    rounds: copyOfRounds
  };

  return updatedQuiz;
}

export function UpdateQuizWithUpdatedRound(quiz: Quiz, updatedRound: Round): Quiz {
  
  let copyOfRounds = quiz.rounds.map(round => {
    if (round.id === updatedRound.id) {
      const updatedQuestions = updatedRound.questionsFromRound.map(question => ({ ...question }));
      return { ...updatedRound, questionsFromRound: updatedQuestions };
    } else {
      const existingQuestions = round.questionsFromRound.map(question => ({ ...question }));
      return { ...round, questionsFromRound: existingQuestions };
    }
  });
  
  const updatedQuiz: Quiz = {
    ...quiz,
    rounds: copyOfRounds
  };
  return updatedQuiz;
}


export function UpdateQuizWithDeleteRound(quiz: Quiz, deletedRound: Round): Quiz {
  let roundsThatStillExist = quiz.rounds.filter(x => x.id !== deletedRound.id);
  let copyOfRounds = [...roundsThatStillExist];

  copyOfRounds.forEach((round:Round, index:number) => {
    round.sequence = index + 1 ;
  });

  const updatedQuiz:Quiz = {
    ...quiz,
    rounds : copyOfRounds
  }

  return updatedQuiz;
}

export function CopyQuizWithCopiesOfRounds(quiz:Quiz, rounds:Round[]){
  let copyOfRounds = [...rounds];
  const updatedQuiz: Quiz = {
      ...quiz,
      rounds: copyOfRounds
    };
  return updatedQuiz;
}