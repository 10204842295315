import { useAuth } from 'react-oidc-context';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import AddOrEditQuiz from '../Shared/AddOrEditQuiz';
import { useEffect } from 'react';

interface EditQuizProps {
    quizId: number
}

const EditQuiz: React.FC<EditQuizProps> = ({quizId}) => {
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);

    useEffect(() => {

    }, [auth])
    
    const saveQuizData = async (data: SaveQuizData) => {
        if(data.quizId != null){
            await quizRepo.UpdateQuiz(() => {}, 
            (errorData: Map<string, string[]>) => { console.log("ERROR CONVERER COMES HERE") },
            data.quizId,
            data)
        }
    }

    return (
        <>
            <AddOrEditQuiz
                showQuizDataTab={true}
                showRoundsTab={true}
                showPlayersTab={true}
                showScoresTab={true}
                quizId={quizId}
                saveQuizData={saveQuizData}
                initialSelectedTabIndex={1}
            />
        </>
    );
};

export default EditQuiz;