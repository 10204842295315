import { useDroppable } from "@dnd-kit/core";
import SortablePlayer from "../../QuizPlayer/Components/SortableQuizPlayer";
import DeleteIcon from "@mui/icons-material/Delete";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import DeleteModal from "../../Shared/Delete/DeleteModal";

interface TeamsProps {
    quizId: number
    quizTeam : QuizTeamWithQuizPlayers
    onPlayerDelete: (playerQuiz: QuizPlayer, quizTeamId: number) => void
    onTeamDelete: (QuizTeam: QuizTeamWithQuizPlayers) => void,
    isEditingName: boolean,
    handleUpdateQuizTeamName: (quizTeamId: number, newQuizTeamName: string) => void
}

function SortableTeam({
    quizTeam,
    onPlayerDelete,
    onTeamDelete,
    isEditingName = true,
    handleUpdateQuizTeamName,
}: TeamsProps)
{
    const auth = useAuth();

    const [temporaryTeamName, setTemporaryTeamName] = useState<string>(quizTeam.name)
    const [isEditing, setIsEditing] = useState(isEditingName);
    const [isHovered, setIsHovered] = useState(false);
    const [focusOnQuizTeam, setFocusOnQuizTeam] = useState(quizTeam.focusOnQuizTeam);
    const [shouldOpenDeleteModal, setShouldDeleteModal] = useState(false);

    const editTeamNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

    }, [auth])

    useEffect(() => {
        if (focusOnQuizTeam && editTeamNameRef.current) {
            editTeamNameRef.current.focus();
            editTeamNameRef.current.select();
            setFocusOnQuizTeam(false);
        }
    }, [focusOnQuizTeam]);

    const { setNodeRef } = useDroppable({
        id: quizTeam.id.toString()
    })

    const handleOnMouseEnter = () => {
        setIsHovered(true)
      }
    
      const handleOnMouseLeave = () => {
        setIsHovered(false)
      }

    const handleOnDeleteButtonClick = () => {
        setShouldDeleteModal(true);
    }

    const onHoverPlayer = (isPlayerHoverd: boolean) => {
        setIsHovered(!isPlayerHoverd);
    }

    const onDeletePlayer = (playerQuiz: QuizPlayer) => {
        onPlayerDelete(playerQuiz, quizTeam.id);
    }

    const handleOnTeamNameClick = () => {
        setIsEditing(true);
    }

    const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemporaryTeamName(event.target.value);
    }

    const handleTeamNameBlur = () => {
        handleUpdateQuizTeamName(quizTeam.id, temporaryTeamName);
        setIsEditing(false);    
    }

    const handelTeamNameFocus = () => {
        if(focusOnQuizTeam){
            if (editTeamNameRef.current) {
                editTeamNameRef.current.select();
            }

            setFocusOnQuizTeam(false);
        }
    }

    return (
        <>
            <SortableContext 
                id={quizTeam.id.toString()} 
                items={quizTeam.quizPlayers.map((quizPlayer) => quizPlayer.id.toString())}
                strategy={verticalListSortingStrategy}              
            >
                <div ref={setNodeRef} className="team-card"
                                    onMouseEnter={handleOnMouseEnter} 
                                    onMouseLeave={handleOnMouseLeave}>
                    <div className="team-name">
                        {
                            quizTeam.id != -1 && 
                            (
                                isEditing && quizTeam.teamId == null ? 
                                (
                                    <>
                                        <input
                                            ref={editTeamNameRef}
                                            type="text"
                                            value={temporaryTeamName}
                                            onChange={handleTeamNameChange}
                                            onBlur={handleTeamNameBlur}
                                            onFocus={handelTeamNameFocus}
                                            autoFocus
                                        />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <span onClick={handleOnTeamNameClick} className={`${quizTeam.teamId != null ? "existing-team" : "guest-team"}`}>
                                            {`${temporaryTeamName}`}
                                        </span>
                                        <IconButton
                                            className={`round-container-button ${isHovered ? '' : "round-container-button-hidden"}`} 
                                            aria-label="delete"
                                            onClick={handleOnDeleteButtonClick}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )
                            )
                        }
                    </div>
                    <div className="player-list">
                        {
                            quizTeam.quizPlayers.map((quizPlayer: QuizPlayer) => (
                                <>
                                    <SortablePlayer 
                                        key={quizPlayer.id.toString()}
                                        dragOverLay={false} 
                                        onHoverPlayer={onHoverPlayer} 
                                        playerQuiz={quizPlayer} 
                                        onPlayerDelete={onDeletePlayer}/>
                                </>
                            ))
                        }
                    </div>
                </div>
            </SortableContext>
                    
            <DeleteModal
                deleteCallback={() => onTeamDelete(quizTeam)}
                setShouldOpenModel={setShouldDeleteModal}
                shouldOpenModal={shouldOpenDeleteModal}
                subject='Team'
                subjectValue={quizTeam.name}
                >
            </DeleteModal>
        </>
    )
}

export default SortableTeam;