import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { RoundPopUpProps } from "../Properties/RoundPopUpProps";
import { useAuth } from "react-oidc-context";
import { CreateOrEditRound } from "../../../interfaces/Round/CreateOrEditRound";

const RoundPopup: React.FC<RoundPopUpProps> = ({
    roundDataProp,
    onRoundPopupDataChange,
    shouldOpenPopup,
    setShouldOpenPopup
}) => {

    const auth = useAuth();
    const [roundData, setRoundData] = useState<CreateOrEditRound>(roundDataProp);

    useEffect(() => {
        setRoundData(roundDataProp);
    }, [auth]);

    function handleClose() {
        setShouldOpenPopup(false);
    }

    const handleOnTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRoundData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRoundData({
            ...roundData,
            [name]: Number(value),
        });
    };

    const handleOnSubmit = (e:any) => {
        handleOnTextChange(e);
        handleNumberInputChange(e);
        onRoundPopupDataChange(roundData);
        handleClose();
        setRoundData(roundDataProp);
    };

    const dialogTitle = () => {
        if (!roundDataProp.isNewRound) {
            return "Een ronde aanpassen"
        }
        else return "Een ronde aanmaken"
    }

    return (
        <Dialog open={shouldOpenPopup} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{dialogTitle()}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="roundName"
                    label="Round Name"
                    type="text"
                    fullWidth
                    value={roundData.roundName}
                    onChange={handleOnTextChange}
                />
                <TextField
                    margin="dense"
                    name="maxPoints"
                    label="Max Points"
                    type="number"
                    fullWidth
                    value={roundData.maxPoints}
                    onChange={handleNumberInputChange}
                />
                {roundData.isNewRound && (
                    <TextField
                        margin="dense"
                        name="countOfQuestions"
                        label="Max Questions"
                        type="number"
                        fullWidth
                        value={roundData.countOfQuestions}
                        onChange={handleOnTextChange}
                    />
                )}

                <TextField
                    margin="dense"
                    name="kindOfRound"
                    label="Kind of Round"
                    select
                    fullWidth
                    value={roundData.kindOfRound}
                    onChange={handleOnTextChange}
                >
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="MultipleChoice">Multiple Choice</MenuItem>
                    <MenuItem value="Video">Video</MenuItem>
                    <MenuItem value="Photo">Photo</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOnSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RoundPopup;
