import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

    const handleQuizBackButton = () => {
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
                backgroundColor: '#f5f5f5',
                padding: 2,
            }}
        >
            <LockOutlinedIcon sx={{ fontSize: 100, color: '#e53935', mb: 2 }} />
            <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
                Geen toegang!
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', maxWidth: 500 }}>
                Het blijkt dat u geen toegang hebt tot deze pagina. Gelieve de administrator te contacteren mocht u denken dat dit een fout is.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleQuizBackButton}>
                Naar de Quizzes!
            </Button>
        </Box>
    );
};

export default UnauthorizedPage;
