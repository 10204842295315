import { FC, useEffect, useState } from 'react';
import Header from '../Shared/Header/Header';
import "./././LoginForm.scss"
import "./../Shared/Header/Header.scss";
import { useAuth } from 'react-oidc-context';

const UserRegisteredView: FC = () => {
    const auth = useAuth();
    const [shouldUpdateUI, setShouldUpdateUI] = useState<boolean>(false);

    useEffect(() => {
        auth.signinRedirect();
    }, [shouldUpdateUI])
  return (
    <>
      <Header></Header>
    </>
  );
  
};

export default UserRegisteredView;
