import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { Question } from '../../../interfaces/Quiz/Question';
import AddIcon from '@mui/icons-material/Add';
import { AnimateLayoutChanges, SortableContext, arrayMove, defaultAnimateLayoutChanges, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, MeasuringStrategy, PointerSensor, TouchSensor, useSensor, useSensors, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import SortableQuestion from './SortableQuestion';
import { CreateQuestion } from "../../../interfaces/Questions/CreateQuestion";
import { CreateQuestionPlacements } from '../../../interfaces/Questions/CreateQuestionPlacement';
import { QuestionRepo } from '../Repo/QuestionRepo';
import { QuestionSequence } from '../../../interfaces/Questions/QuestionSequence';
import { useAuth } from 'react-oidc-context';
import { QuizRepo } from '../../Quiz/Repo/QuizRepo';
import { UpdateQuestion } from '../../../interfaces/Questions/UpdateQuestion';
import { GenerateUpdatedQuizObjectWithNewSequence, UpdateQuizWithDeletedQuestion, UpdateQuizWithUpdatedRoundAndQuestionChange, UpdateQuizWithUpdatedRoundAndUpdatedQuestion } from '../Shared/QuestionStateHelper';
import { QuestionListProps } from '../Properties/QuestionListProps';

function QuestionList({ selectedRound, quiz, onQuizChange }: QuestionListProps) {
  const auth = useAuth();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
  const descriptionRef = useRef<HTMLInputElement | null>(null);
  const quizRepo = new QuizRepo(auth);
  const questionRepo = new QuestionRepo(auth);

  useEffect(() => {
  }, [selectedRound, auth]);

  async function handleOnDeleteQuestion(questionId: number) {
    await quizRepo.DeleteQuestion(async () => {
      let updatedQuiz = UpdateQuizWithDeletedQuestion(quiz, selectedRound, questionId)
      onQuizChange(updatedQuiz);
    }, questionId);
  }

  function handleAddQuestionClick() {
    let sequence = quiz.rounds.find(x => x.id == selectedRound.id).questionsFromRound.length + 1
    let selectedRoundIndex = quiz.rounds.findIndex(x => x.id === selectedRound.id);
    if (selectedRoundIndex === -1) return;
    const newQuestion: CreateQuestion = {
      roundId: selectedRound?.id || 0,
      name: "",
      answer: "",
      placement: CreateQuestionPlacements.END
    };

    quizRepo.CreateQuestion((questionId: number) => {
      const newQuestionUI: Question = {
        id: questionId,
        answer: "",
        name: "",
        description: "",
        sequence: sequence,
        roundId: selectedRound.id
      };

      let updatedQuiz = UpdateQuizWithUpdatedRoundAndUpdatedQuestion(quiz, selectedRound, newQuestionUI, sequence);
      
      onQuizChange(updatedQuiz);

      setTimeout(() => {
        if (descriptionRef.current) {
          descriptionRef.current.focus();
          descriptionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);

    }, newQuestion);
  }

  // Desktop and Mobile
  const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true })
  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const roundIndex = quiz.rounds.findIndex((round) => round.id === selectedRound.id);
    if (roundIndex === -1) return;

    const foundRound = quiz.rounds[roundIndex];

    const activeQuestion = foundRound.questionsFromRound.find((q) => q.id == active.id);
    const overQuestion = foundRound.questionsFromRound.find((q) => q.id == over.id);

    if (!activeQuestion || !overQuestion) return;

    const activeIndex = foundRound.questionsFromRound.findIndex((q) => q.id === activeQuestion.id);
    const overIndex = foundRound.questionsFromRound.findIndex((q) => q.id === overQuestion.id);

    if (activeIndex !== overIndex) {
      const { updatedQuiz, newArray } = GenerateUpdatedQuizObjectWithNewSequence(foundRound, activeIndex, overIndex, quiz, roundIndex);

     onQuizChange(updatedQuiz);

      const questionSequence: QuestionSequence = {
        sequencedQuestionsIds: newArray.map((question) => question.id)
      };

      await questionRepo.CalculateQuestionSequencesAsync(questionSequence);
    }
  }

  function onQuestionChange(updatedQuestion: UpdateQuestion) {
      let updatedQuiz = UpdateQuizWithUpdatedRoundAndQuestionChange(quiz, selectedRound, updatedQuestion);
      onQuizChange(updatedQuiz);
  }


  return (
    <>
      <div id="question-list">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          measuring={measuringConfig}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={quiz.rounds.find(x => x.id == selectedRound.id).questionsFromRound}
            strategy={verticalListSortingStrategy}
          >
            {quiz.rounds.find(x => x.id == selectedRound.id).questionsFromRound.map((q) => (
              <SortableQuestion
                key={`${q.id}`}
                initialQuestion={q}
                onDeleteQuestion={handleOnDeleteQuestion}
                quiz={quiz}
                selectedRound={selectedRound}
                onQuestionChange={onQuestionChange}
                descriptionRef={descriptionRef}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <div>
        <Button
          variant="outlined"
          startIcon={<AddIcon className='AddQuestionIconButton' />}
          fullWidth
          className="question-list-element"
          onClick={handleAddQuestionClick}
        >
        </Button>
      </div>
    </>
  );
}

export default QuestionList;
