import { useEffect, useState } from 'react';
import RoundList from './RoundList';
import QuestionList from '../../Question/Components/QuestionList';
import { Round } from '../../../interfaces/Quiz/Round';
import { CreateRoundPlacements } from '../../../interfaces/Round/CreateRoundPlacement';
import { CreateRound } from "../../../interfaces/Round/CreateRound";
import { RoundUpdate } from '../../../interfaces/Round/UpdateRound';
import { KindOfROundChoices } from '../../../interfaces/Round/KindOfRoundChoices';
import { useAuth } from 'react-oidc-context';
import { RoundRepo } from '../Repo/RoundRepo';
import { QuizRepo } from '../../Quiz/Repo/QuizRepo';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { CopyQuizWithCopiesOfRounds, UpdateQuizWithDeleteRound, UpdateQuizWithNewRound, UpdateQuizWithUpdatedRound } from '../Shared/RoundStateHelper';
import RoundPopup from './RoundPopup';
import { CreateOrEditRound } from '../../../interfaces/Round/CreateOrEditRound';

interface QuizDataProps {
    quizId: number
}

const Rounds: React.FC<QuizDataProps> = ({
    quizId
}) => {
    const auth = useAuth();
    const roundRepo = new RoundRepo(auth);
    const quizRepo = new QuizRepo(auth);
    const [selectedRound, setSelectedRound] = useState<Round>({} as Round);
    const [quiz, setQuiz] = useState<Quiz>({} as Quiz)
    const [loading, setLoading] = useState(true);
    const [shouldOpenPopup, setShouldOpenPopup] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            await quizRepo.GetQuizAsync((data: Quiz) => {
                setQuiz(data);
                setSelectedRound(data.rounds[0]);
                setLoading(false);
            }, quizId);
        };
        fetchData();
    }, [quizId, auth]);

    //TODO: This also triggers on a hoverOver, it should not happen
    const onRoundChange = (updatedRound: CreateOrEditRound) => {
        if (selectedRound) {
            const updateRound: RoundUpdate = {
                id: selectedRound.id,
                name: updatedRound.roundName,
                kindOfRound: updatedRound.kindOfRound,
                maxPoints: updatedRound.maxPoints,
                quizId: updatedRound.quizId,
            };

            roundRepo.UpdateRound((round:Round) => {
                const updatedRound: Round = {
                    id: round.id,
                    name: round.name,
                    totalQuestions : round.totalQuestions,
                    questionsFromRound: round.questionsFromRound,
                    maxPoints: round.maxPoints,
                    sequence : quiz.rounds.length + 1,
                    quizId : quiz.id,
                    kindOfRound :  round.kindOfRound
                }
                let updatedQuizWithRound = UpdateQuizWithUpdatedRound(quiz, updatedRound);
                onQuizChange(updatedQuizWithRound);
            }, updateRound, quizId);
        }
    }

    const defaultNewRound = (): CreateOrEditRound => {
        return {
            kindOfRound: "Normal",
            maxPoints : 10,
            countOfQuestions : 10,
            quizId : quizId,
            roundName : "",
            roundId : selectedRound.id,
            isNewRound: true
        };
    };
    
    function handleRoundClick(round: Round) {
        setSelectedRound(round);
    }

    function onAddRoundClick() {
        setShouldOpenPopup(true);
    }

    function saveNewRound(roundData:CreateOrEditRound) {
        const newRound: CreateRound = {
            quizId: quizId,
            name: roundData.roundName,
            countOfQuestions : roundData.countOfQuestions,
            maxPoints : roundData.maxPoints,
            kindOfRound: KindOfROundChoices.NORMAL,
            placement: CreateRoundPlacements.END
        };

        roundRepo.CreateRoundAsync((createdRound: Round) => {
            const newRoundUi: Round = {
                id: createdRound.id,
                name: createdRound.name,
                totalQuestions : createdRound.totalQuestions,
                questionsFromRound: createdRound.questionsFromRound,
                maxPoints: createdRound.maxPoints,
                sequence : quiz.rounds.length + 1,
                quizId : quiz.id,
                kindOfRound: createdRound.kindOfRound
            }
          setSelectedRound(newRoundUi);
          let updatedQuiz = UpdateQuizWithNewRound(quiz, newRoundUi);
          onQuizChange(updatedQuiz)
        }, newRound);
    }

    function handleOnReorderRounds(rounds: Round[]) {
        let updatedQuiz = CopyQuizWithCopiesOfRounds(quiz, rounds);
        setQuiz(updatedQuiz)
    }

    function handelOnDeleteRound(roundId: number) {
        let roundIndex = quiz.rounds.findIndex(x => x.id === roundId);
        if (roundIndex === -1) return;
        let roundToDelete = quiz.rounds[roundIndex];

        if (quiz.rounds.length === 1) {
            alert("Won't delete, at least one round must exist"); // TODO: Replace alert with popup
            return;
        }
    
        let updatedQuiz = UpdateQuizWithDeleteRound(quiz, roundToDelete);
        roundRepo.DeleteRound(() => {
            let selectedRoundIndex = roundIndex - 1;
            if (selectedRoundIndex < 0) selectedRoundIndex = 0;
            if (selectedRoundIndex >= updatedQuiz.rounds.length) selectedRoundIndex = updatedQuiz.rounds.length - 1;
    
            onQuizChange(updatedQuiz);
            setSelectedRound(updatedQuiz.rounds[selectedRoundIndex]);

        }, quizId, roundId);
    }

    function onQuizChange(quiz: Quiz) {
        setQuiz({...quiz});
        setSelectedRound({...quiz.rounds.find(round => round.id === selectedRound.id)});
    }

    if (loading) { //(FCO) => Make this more good looking and middle of the page (Make sure this is shared component so we can use it acrross the page)
        return <div>Loading...</div>;
    }

    return (
        <>
            <div id="round-container">
                <div id="round-list-container">
                    <div className="round-column-content">
                        <RoundList
                            quiz={quiz}
                            selectedRound={selectedRound}
                            onRoundClick={handleRoundClick}
                            onAddRound={onAddRoundClick}
                            onReorderRounds={handleOnReorderRounds}
                            onDeleteRound={handelOnDeleteRound}
                            onRoundChange={onRoundChange} />
                    </div>
                </div>

                <div id="question-list-container">
                    <div className="question-column-content">
                        <QuestionList
                            selectedRound={selectedRound}
                            quiz={quiz}
                            onRoundChange={onRoundChange}
                            onQuizChange={onQuizChange}
                        />
                    </div>
                </div>
            </div>

            <RoundPopup
                roundDataProp={defaultNewRound()}
                onRoundPopupDataChange={saveNewRound}
                shouldOpenPopup={shouldOpenPopup}
                setShouldOpenPopup={setShouldOpenPopup}></RoundPopup>
        </>
    );
}

export default Rounds;