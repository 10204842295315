import { FC } from 'react';
import Header from '../Shared/Header/Header';
import "./././LoginForm.scss"
import "./../Shared/Header/Header.scss";
import LoginForm from './Components/LoginForm';

const LoginComponent: FC = () => {
  return (
    <>
      <Header></Header>
      <main>
        <LoginForm></LoginForm>
      </main>
    </>
  );
};

export default LoginComponent;
