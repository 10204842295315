import { getApiClient } from "../../Shared/ApiClient/AxiosClient";
import { FullQuiz } from "../../../interfaces/Quiz/GetFullQuiz"
import { Quiz } from "../../../interfaces/Quiz/Quiz"
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { QuestionSequence } from "../../../interfaces/Questions/QuestionSequence";
import { CreateQuestion } from "../../../interfaces/Questions/CreateQuestion";
import { SaveQuizData } from "../../../interfaces/Quiz/SaveQuizData";
import { Question } from "../../../interfaces/Quiz/Question";
import { QuizTableRow } from "../../../interfaces/Quiz/QuizTableRow";
import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import { TeamRoundPoint } from "../../../interfaces/Quiz/TeamRoundPoint";
import { SendEmailInvites } from "../../../interfaces/Quiz/SendEmailInvites";

export class QuizRepo{
  private readonly apiClient:AxiosInstance;
  
  constructor(auth:AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

  async GetAllQuizzes(callback: any): Promise<void> {
    await this.apiClient.get("/quiz").then((res: any) => {
      let data = res.data as unknown as QuizTableRow[];
      callback(data);
    })
  }
  
  async GetQuizAsync(callback: any, id: number): Promise<void> {
    await this.apiClient.get(`/quiz/${id}`).then((res: any) => {
      let data = res.data as unknown as Quiz;
      callback(data);
    })
  }
  
  async createQuizAsync(callback: any, errorCallBack: any, requestBody: SaveQuizData): Promise<void> {
    await this.apiClient.post(`/quiz`, requestBody)
      .then((result:any) => { callback(result.data) })
      .catch(error => { errorCallBack(error.response.data) })
  }
  
  async UpdateQuiz(callback: any, errorCallBack: any, quizId: number, requestBody: SaveQuizData): Promise<void> {
  
    await this.apiClient.put(`quiz/${quizId}`, requestBody)
      .then(res => { callback() })
      .catch(error => { errorCallBack(error.response.data) })
  }
  
  async GetQuestion(id: number): Promise<Question> {
    try {
      const response = await this.apiClient.get(`/question/${id}`)
      return response.data as Question;
    }
    catch (error) {
      throw error
    }
  }
  
  async UpdateQuestion(callback:any, updateQuestion: UpdateQuestion): Promise<void> {
      await this.apiClient.put("/question/", updateQuestion)
      .then(() => callback());
  
  }
  
  async DeleteQuestion(callback:any, id: number): Promise<void> {
    await this.apiClient.delete(`/question/${id}`)
    .then(() => callback());
  
  }
  
  async CalculateQuestionSequences(callback:any, questionSequenceDto: QuestionSequence): Promise<void> {
    await this.apiClient.post(`/question/sequence`, questionSequenceDto)
      .then((res: any) => {
        callback();
      });
  }
  
  async CreateQuestion(callback:any, createQuestion: CreateQuestion): Promise<void> {
    await this.apiClient.post("/question", createQuestion)
    .then((res: any) => {
      let id = res.data.questionId;
      callback(id);
    })
  }
  
  async GetQuestionsById(callback: any, roundId: number): Promise<void>{
    await this.apiClient.get(`/question?roundId=${roundId}`)
      .then((res: any) => {
        let data = res.data as Question[];
        callback(data);
      })
  }  
  
  
  async GeneratePowerpoint(callback: any, quizId: number, filename: string): Promise<void> {
    await this.apiClient.get(`/quiz/GeneratePowerpoint/${quizId}`, { responseType: 'blob' })
    .then((res: any) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .then((res: any) => {
      callback();
    })
  }

  async GetScores(callback: any, quizId: number): Promise<void> {
    await this.apiClient.get(`/Quiz/${quizId}/Scores`)
      .then((res: any) => {
        let data = res.data as TeamRoundPoint[];
        callback(data);
      })
  }

  async sendEmailInvites(callback: any, errorCallBack: any, quizId: number, requestBody: SendEmailInvites): Promise<void> {
    await this.apiClient.post(`/Quiz/${quizId}/SendInvitePlayers`, requestBody)
      .then((result:any) => { callback(result.data) })
      .catch(error => { errorCallBack(error.response.data) })
  }
}
