import react, { FC } from 'react';
import Header from '../../Shared/Header/Header';
import QuizIndexTable from '../Components/QuizIndexTable';
import "../../Shared/DataGrid/Datagrid.scss"
import "../../Shared/Misc/GeneralStyling.scss"


const QuizIndexView: FC = () => {
  return (
    <>
      <Header></Header>
      <main className="tableContainer">
        <QuizIndexTable></QuizIndexTable>
      </main>
    </>
  );
};

export default QuizIndexView;
