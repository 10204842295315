  import { FC, useEffect, useState } from 'react';
  import { QuizRepo } from '../Repo/QuizRepo';
  import { QuizTableRow } from '../../../interfaces/Quiz/QuizTableRow';
  import { GridColDef, GridRowParams } from '@mui/x-data-grid';
  import StyledDataGrid from '../../Shared/DataGrid/StyledDataGrid';
  import AddIcon from '@mui/icons-material/Add';
  import "../../Shared/DataGrid/Datagrid.scss";
  import { useNavigate } from 'react-router-dom';
  import EditIcon from '@mui/icons-material/Edit';
  import { QuizListItem } from '../../../interfaces/Quiz/QuizListItem';
  import { useAuth } from 'react-oidc-context';
  import { UserRoles } from '../../Shared/Misc/UserRoles';
  import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';

  function generateGridRow(quizItem: QuizListItem): QuizTableRow {
    return {
      id: quizItem.id,
      name: quizItem.name,
      ownerId: quizItem.ownerId,
      ownerName: quizItem.ownerName,
      startDate: quizItem.startDate,
      publishDate: quizItem.publishDate,
      isPublished: quizItem.isPublished,
      isQuizOwner: quizItem.isQuizOwner
    } as QuizTableRow
  }

  const QuizIndexTable: FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const [shouldUpdateTable, setShouldUpdateTable] = useState<boolean>(false);
    const [quizzesWithPlayers, setQuizzesWithPlayers] = useState<QuizTableRow[]>([]);
    const [quizRepoReady, setQuizRepoReady] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  
    const getColumnDefinitions = () => {
      // Define all columns
      const baseColumns: GridColDef[] = [
        { headerName: "Id", field: "id", headerClassName: 'tableHeader', flex: 1 },
        { headerName: "Quiz Naam", field: "name", headerClassName: 'tableHeader', flex: 1, },
        { headerName: "Quizmaster", field: "ownerName", headerClassName: 'tableHeader', flex: 1, },
        {
          headerName: "Start", field: "startDate", headerClassName: 'tableHeader', flex: 1,
          renderCell: (params) => {
            if (!params.row.startDate) return (<></>);
    
            const startDate = new Date(params.row.startDate);
    
            const formattedDate = startDate.toLocaleDateString('nl-BE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            });
    
            return (
              <>
                {formattedDate}
              </>
            )
          }
        },
        {
          headerName: "Publiceerdatum", field: "publishDate", headerClassName: 'tableHeader', flex: 1,
          renderCell: (params) => {
            if (!params.row.startDate) return (<></>)
    
            const startDate = new Date(params.row.publishDate);
    
            const formattedDate = startDate.toLocaleDateString('nl-BE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            });
    
            return (
              <>
                {formattedDate}
              </>
            )
          }
        },
        {
          headerName: "Gepubliceerd", field: "isPublished", headerClassName: 'tableHeader', flex: 1, type: 'boolean'
        }
      ];
      // Conditionally add the "Acties" column if the user role is admin
      if (auth.user?.profile.role == UserRoles.Admin || auth.user?.profile.role == UserRoles.QuizCreator) {
        baseColumns.push({
          field: "",
          headerName: "Acties",
          headerClassName: "tableHeader",
          sortable: false,
          flex: 1,
          renderCell: (params) => {
            if(params.row.isQuizOwner || auth.user?.profile.role == UserRoles.Admin){
              return (
                <>
                  <a onClick={() => onEditQuizClick(params.row)}><EditIcon></EditIcon></a>
                </>
              );
            }
            return <></>
          }
        });
      }
      return baseColumns;
    };
    

    function onEditQuizClick(row: QuizTableRow) {
      if(row.isQuizOwner || auth.user?.profile.role == UserRoles.Admin){
        navigate(`/quiz/edit/${row.id}`)
      }
      else {
        setShowSnackbar(true);
      }
    }

    const handleRowDoubleClick = (params: GridRowParams) => {
      const row = params.row;
      onEditQuizClick(row);
    };

    useEffect(() => {
      const fetchData = async () => {
        await quizRepo.GetAllQuizzes(mapQuizToQuizTableRow)

        function mapQuizToQuizTableRow(quizListItems: QuizListItem[]) {
          let mappedQuizzes = quizListItems.map((quizListItem: QuizListItem) => {
            return generateGridRow(quizListItem);
          }) as QuizTableRow[];
          setQuizzesWithPlayers(mappedQuizzes);
        }
      }

      fetchData()
        .catch(console.error);
      setQuizRepoReady(true)
    }, [auth]);

    return (
      <>
        <div className='tableHeaderContainer'>
          <h1>Quizzes</h1>
          <a className='pointerMouse' onClick={() => navigate("/quiz/add")}><AddIcon fontSize='large'></AddIcon></a>
        </div>

        <div className="tableInnerContainer">
          <StyledDataGrid onRowDoubleClick={handleRowDoubleClick} columns={getColumnDefinitions()} dataset={quizzesWithPlayers} children={null} noRowsText='U hebt geen deelnemende quizzen'></StyledDataGrid>
          {
            <SnackbarNotification
              textToDisplay='Onvoldoende rechten voor actie'
              setShouldOpenSnackbar={setShowSnackbar}
              shouldOpenSnackbar={showSnackbar}
              horizontalLocation='center'
              verticalLocation='top'
              severity='warning'
              >
            </SnackbarNotification>
          }
        </div>


      </>
    );
  };

  export default QuizIndexTable;
