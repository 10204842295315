import { Alert, Snackbar } from "@mui/material";
import { FC } from "react";
import { SnackbarNotificationProps } from "./SnackbarNotificationProps";

const SnackbarNotification: FC<SnackbarNotificationProps>
 = ({shouldOpenSnackbar, setShouldOpenSnackbar, textToDisplay, severity, horizontalLocation, verticalLocation}) => {
    
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShouldOpenSnackbar(false);
    }

    return (
        <>
            <Snackbar open={shouldOpenSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
                anchorOrigin={{horizontal: horizontalLocation, vertical: verticalLocation}}
            >
             <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
               {textToDisplay}
             </Alert>
           </Snackbar>
        </>
    );
};


export default SnackbarNotification