import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";

interface SortablePlayerProps {
  playerQuiz: QuizPlayer;
  onHoverPlayer: (hovered: boolean) => void;
  onPlayerDelete: (playerQuiz: QuizPlayer) => void;
  dragOverLay: boolean
}

function SortablePlayer({ playerQuiz, onHoverPlayer, onPlayerDelete, dragOverLay }: SortablePlayerProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: playerQuiz.id.toString(),
  });

  const [isHovered, setIsHovered] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnMouseEnter = () => {
    setIsHovered(true)
    onHoverPlayer(true)
  }

  const handleOnMouseLeave = () => {
    setIsHovered(false)
    onHoverPlayer(false)
  }

  const handleOnDeleteButtonClick = () => {
    onPlayerDelete(playerQuiz)
  }

  const className = `player-card ${dragOverLay ? "player-card-drag-overlay" : "player-card-regular"}`

  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      onMouseEnter={handleOnMouseEnter} 
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="player-avatar">
        {playerQuiz.initials}
      </div>

      <div className="player-name" style={{ flexWrap: "wrap" }}>
        {playerQuiz.quizPlayerName}
      </div>

      <div className="player-buttons">
      <IconButton
              className={`round-container-button ${isHovered ? '' : "round-container-button-hidden"}`} 
              aria-label="Delete"
              onClick={handleOnDeleteButtonClick}
            >
              <DeleteIcon />
        </IconButton>
        <IconButton className="round-container-drag" color="primary" aria-label="Drag" {...attributes} {...listeners}>
              <DragIndicatorIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default SortablePlayer;
