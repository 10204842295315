import { AuthContextProps, useAuth } from "react-oidc-context";
import { CreateQuestion } from "../../../interfaces/Questions/CreateQuestion";
import { QuestionSequence } from "../../../interfaces/Questions/QuestionSequence";
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { Question } from "../../../interfaces/Quiz/Question";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { QuizRepo } from "../../Quiz/Repo/QuizRepo";
import { AxiosInstance } from "axios";


export class QuestionRepo {
  private readonly apiClient:AxiosInstance;
  
  constructor(auth:AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

  async GetQuestionAsync(id: number): Promise<Question> {
    try {
      const response = await this.apiClient.get(`/question/${id}`)
      return response.data as Question;
    }
    catch (error) {
      throw error
    }
  }

  async UpdateQuestionAsync(updateQuestionDto: UpdateQuestion): Promise<void> {
    try {
      await this.apiClient.put("/question/", updateQuestionDto);
    }
    catch (error) {
      throw error;
    }
  }

  async DeleteQuestionAsync(id: number): Promise<void> {
    try {
      const response = await this.apiClient.delete(`/question/${id}`)
    }
    catch (error) {
      throw error
    }
  }

  async CalculateQuestionSequencesAsync(questionSequenceDto: QuestionSequence): Promise<void> {
    try {
      const response = await this.apiClient.post(`/question/sequence`, questionSequenceDto);
    }
    catch (error) {
      throw error
    }
  }

  async CreateQuestionAsync(createQuestionDto: CreateQuestion): Promise<number> {
    try {
      const response = await this.apiClient.post(`/question`, createQuestionDto);
      return response.data.questionId as number;
    }
    catch (error) {
      throw error
    }
  }
}

