import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, FormControlLabel, FormGroup, Button, Switch,  Tooltip, AlertColor } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EmailIcon from '@mui/icons-material/Email';
import { useAuth } from 'react-oidc-context';
import SendEmailQuizPlayersModal from './SendEmailQuizPlayersModal';
import { QuizPlayer } from '../../../interfaces/QuizPlayer/QuizPlayer';
import { SendEmailInvites } from '../../../interfaces/Quiz/SendEmailInvites';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';

interface QuizDataProps {
  quizId: number | null;
  saveQuizData: (data: SaveQuizData) => void;
}

const QuizData: React.FC<QuizDataProps> = ({ quizId, saveQuizData }) => {
  const [quizName, setQuizName] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [publicationDate, setPublicationDate] = useState<Dayjs | null>(null);
  const [isPublished, setIsPublished] = useState(false);
  const [quizNameError, setQuizNameError] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [quizPlayers, setQuizPlayers] = useState<QuizPlayer[]>([]); 
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);
  
  useEffect(() => {
    const fetchData = async () => {
        if(quizId != null){
            await quizRepo.GetQuizAsync((data: Quiz) => {
                setQuizName(data.name);
                setIsPublished(data.isPublished);

                if(data.startDate != null){
                    setStartDate(dayjs(data.startDate));
                    setPublicationDate(dayjs(data.publishDate));
                }

                setQuizPlayers(data.quizPlayers);

            }, quizId);
        }
    };

        fetchData();
  }, [quizId, auth]);

function sendInvitationMail(userIds: string[]){
  setIsModalOpen(false);

  quizRepo.sendEmailInvites(() => 
  {  
    setSnackbarMessage("Invitaties zijn succesvol verstuurd");
    setShowSnackbar(true);

    setQuizPlayers(prevPlayers => 
      prevPlayers.map(player => 
        userIds.includes(player.userId || '')
          ? { ...player, isInvited: true }
          : player
      )
    );
  }, 
  () => {},
  quizId, 
  { userIds: userIds} as SendEmailInvites)
}

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      setStartDate(date);
      const newPublicationDate = date.add(4, 'hours');
      setPublicationDate(newPublicationDate);
    }
  };

  const handleIsPublishedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPublished(event.target.checked);
  };

  const handleOnGeneratePowerpointClick = () => {
    const fileName = getCurrentDateTimeString() + "_" + quizName + ".pptx";
    quizRepo.GeneratePowerpoint(() => {
      console.log("It's Over");
    },
    quizId ?? 0, fileName)
  }

  const handleQuizDataOnSubmit = (event: FormEvent) => {
    event.preventDefault();
  
    if (!validateForm()) return;
    setQuizNameError('');

    const formData: SaveQuizData = {
      quizId: quizId,
      name: quizName,
      startDate: startDate ? setTimeZoneOffset(startDate.toDate()) : null,
      publishDate: publicationDate ? setTimeZoneOffset(publicationDate.toDate()): null,
      isPublished: isPublished,
    };
  
    saveQuizData(formData);
  };

  const validateForm = () => {
    var isValid = true;

    if(quizName.trim() === '')
    {
        setQuizNameError("Verplicht veld");
        isValid = false;
    }

    return isValid;
  }

  function getCurrentDateTimeString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  function setTimeZoneOffset(date: Date, targetOffset = 0) {
    const currentOffset = date.getTimezoneOffset();
    const newDate = new Date(date);
  
    const offsetDifference = targetOffset - currentOffset;
    newDate.setMinutes(newDate.getMinutes() + offsetDifference);

    return newDate;
  }

  function handleLeaderboardClick(){
    alert("Leaderboard Clicked")
  }

  const handleSendMailsClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
      <>
      <form id="quiz-data-form">
        <TextField
          label="Naam"
          variant="outlined"
          value={quizName}
          className='form-input'
          error={Boolean(quizNameError)}
          helperText={quizNameError}
          onChange={(e) => setQuizName(e.target.value)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Startdatum"
            onChange={(date) => handleStartDateChange(date)}
            value={startDate}
            className='form-input'
            ampm={false}
            slotProps={{
                actionBar: {
                  actions: ['clear']
                }
              }}
          />

          <DateTimePicker
            label="Publiceerdatum"
            value={publicationDate}
            className='form-input'
            ampm={false}
            slotProps={{
                actionBar: {
                  actions: ['clear']
                }
              }}
          />
        </LocalizationProvider>

        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublished} onChange={handleIsPublishedChange} />}
            className='form-input'
            label="Gepubliceerd"
          />
        </FormGroup>
          {
              quizId != null && 
              (
                <div className="quiz-actions-button-list">
                <Tooltip title="Genereer PowerPoint" arrow>
                  <Button variant="contained" onClick={handleOnGeneratePowerpointClick}>
                    <AutoAwesomeMotionIcon/>
                  </Button>
                </Tooltip>
                <Tooltip title="Leaderboard">
                  <Button variant="contained" onClick={handleLeaderboardClick} >
                    <FormatListNumberedIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Verstuur Mails">
                  <Button variant="contained" onClick={handleSendMailsClick}>
                    <EmailIcon />
                  </Button>
                </Tooltip>
              </div>
              )
          }    
          <Button variant="contained" onClick={handleQuizDataOnSubmit} startIcon={<SaveIcon />}>
            Opslaan Quiz
        </Button>
      </form>
          <SendEmailQuizPlayersModal open={isModalOpen} onClose={handleCloseModal} 
                  quizPlayers={quizPlayers.filter(quizPlayer => quizPlayer.userId !== null)} 
                  handleSendMail={sendInvitationMail}/> 

      <SnackbarNotification
              textToDisplay={snackbarMessage}
              setShouldOpenSnackbar={setShowSnackbar}
              shouldOpenSnackbar={showSnackbar}
              horizontalLocation='center'
              verticalLocation='top'
              severity='success'
            >
      </SnackbarNotification>
      </>
  );
};

export default QuizData;
