import axios from "axios";
import { AuthContextProps } from "react-oidc-context";

let baseURI: string | undefined = process.env.REACT_APP_APIURI;


export function getApiClient(auth:AuthContextProps) {
  const apiClient = axios.create({
    baseURL: baseURI,
    headers: {
      'Authorization': `Bearer ${auth.user?.access_token}`,
    }
  });
  return apiClient;
};

export default getApiClient;
