import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QuizData from '../Components/QuizData';
import Rounds from '../../Round/Components/Rounds';
import Scores from '../../Scores/Components/Scores';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ListIcon from '@mui/icons-material/List';
import GroupIcon from '@mui/icons-material/Group';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import Players from '../../QuizPlayer/Components/QuizPlayers';
import { useAuth } from 'react-oidc-context';
import { QuizRepo } from '../Repo/QuizRepo';
import UnauthorizedPage from '../../Shared/Unauthorized/UnauthorizedPage';
import { UserRoles } from '../../Shared/Misc/UserRoles';
import { Quiz } from '../../../interfaces/Quiz/Quiz';

interface AddOrEditQuizProps {
  showQuizDataTab: boolean;
  showRoundsTab: boolean;
  showPlayersTab: boolean;
  showScoresTab: boolean;
  quizId: number | null;
  saveQuizData: (data: SaveQuizData) => void;
  initialSelectedTabIndex: number
}

const AddOrEditQuiz: React.FC<AddOrEditQuizProps> = ({
  showQuizDataTab,
  showRoundsTab,
  showPlayersTab,
  showScoresTab,
  quizId,
  saveQuizData,
  initialSelectedTabIndex
}) => {
  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);
  const [selectedTabIndex, setselectedTabIndexIndex] = useState<number>(initialSelectedTabIndex);
  const [loggedInUserHasAccessToPage, setLoggedInUserHasAccessToPage] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if(quizId != null || quizId != undefined){
        await quizRepo.GetQuizAsync((quiz:Quiz) => {
          setLoggedInUserHasAccessToPage(quiz.isQuizOwner)
        },quizId);
        setLoggedInUserHasAccessToPage(true);
      }
    }
    fetchData()
      .catch(console.error);
  }, [auth]);


  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setselectedTabIndexIndex(newValue);
  };

  const AddOrEditContainer = () => {
    return (
      <>
        <div id="AddOrEditContainter">
          <div id="quiz-tabs">
            <Tabs value={selectedTabIndex} onChange={handleTabChange}>
              {showQuizDataTab && <Tab icon={<QuestionMarkIcon />} label="Quiz" />}
              {showRoundsTab && <Tab icon={<ListIcon />} label="Rondes" />}
              {showPlayersTab && <Tab icon={<GroupIcon />} label="Spelers" />}
              {showScoresTab && <Tab icon={<LeaderboardIcon />} label="Scores" />}
            </Tabs>
          </div>
          <div id="quiz-tab-content">
            {selectedTabIndex === 0 && showQuizDataTab &&
              <div id="quizDataTab">
                  <QuizData
                    quizId={quizId}
                    saveQuizData={saveQuizData} />
                </div>
            }

            {
              quizId === null ? (
                <></>
              ) : (
                <>
                  {selectedTabIndex === 1 && showRoundsTab && (
                    <div id="roundDataTab"><Rounds quizId={quizId} /></div>
                  )}

                  {selectedTabIndex === 2 && showPlayersTab && (
                    <div id="playersDataTab"><Players quizId={quizId} /></div>
                  )}

                  {selectedTabIndex === 3 && showScoresTab && (
                    <div id="scoreDataTab"><Scores quizId={quizId} /></div>
                  )}
                </>
              )
            }
          </div>
        </div>
      </>
    )
  }

  const AddOrEditQuizComponent = () => {
    //(FCO) => if this ID is null or defined it would seems we are on the ADD page
    if( quizId == null && auth.user?.profile.role != UserRoles.QuizParticipator){
      return <AddOrEditContainer></AddOrEditContainer>
    }
    else if(loggedInUserHasAccessToPage || auth.user?.profile.role != UserRoles.QuizParticipator) return <AddOrEditContainer></AddOrEditContainer>
    else return <UnauthorizedPage></UnauthorizedPage>
  }

  return (
    <>
      <AddOrEditQuizComponent></AddOrEditQuizComponent>
    </>

  );
};

export default AddOrEditQuiz;
