import { FC } from 'react';
import "../../Shared/Misc/GeneralStyling.scss";
import "../../Shared/Header/Header.scss";
import "../../Shared/Form/Formlayout.scss";
import "../Style/QuizStyle.scss"
import Header from '../../Shared/Header/Header';
import EditQuiz from '../Components/EditQuiz';
import { useParams } from 'react-router-dom';

const EditQuizView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const quizId = id ? parseInt(id, 10) : 0;

  return (
    <>
      <Header></Header>
      <main className='formContainer'>
        <div className='edit-quiz-container'>
          <EditQuiz
            quizId={quizId}/>
        </div>
      </main>
    </>
  );
};

export default EditQuizView;
