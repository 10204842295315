import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import getApiClient from "../../Shared/ApiClient/AxiosClient";

export class ScoresRepo {
    private readonly apiClient:AxiosInstance;
  
    constructor(auth:AuthContextProps) {
      this.apiClient = getApiClient(auth);
    }

      async UpdateQuizTeamRoundScore(callback: any, quizId: number, quizTeamId: number, roundId: number, points: number): Promise<void> {
        await this.apiClient.put(`/quiz/${quizId}/Scores/Round/${roundId}/QuizTeam/${quizTeamId}`, {
            "points": points
        })
          .then(() => callback());
      }
}