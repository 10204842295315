import React, { useEffect, useRef, useState } from 'react';
import SortableTeam from '../../QuizTeam/Components/SortableQuizTeam';
import SortablePlayer from './SortableQuizPlayer';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import { AlertColor, Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteValue, Button, Checkbox, TextField, useAutocomplete } from '@mui/material';
import { Active, DndContext, DragEndEvent, DragMoveEvent, DragOverEvent, DragOverlay, DragStartEvent, KeyboardSensor, MeasuringStrategy, Over, PointerSensor, TouchSensor, UniqueIdentifier, closestCenter, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { QuizPlayerStatus } from '../../../interfaces/QuizPlayer/QuizPlayerStatus';
import { UpdateQuizTeam as updateQuizTeam } from "../../../interfaces/QuizTeam/UpdateQuizTeam";
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { QuizTeamWithQuizPlayers } from '../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers';
import { QuizPlayer } from '../../../interfaces/QuizPlayer/QuizPlayer';
import { CreateQuizTeam as createQuizTeam } from '../../../interfaces/QuizTeam/CreateQuizTeam';
import { CreateQuizPlayer } from '../../../interfaces/QuizPlayer/CreateQuizPlayer';
import { QuizPlayerKind } from '../../../interfaces/QuizPlayer/QuizPlayerKind';
import { useAuth } from 'react-oidc-context';
import { QuizTeamRepo } from '../../QuizTeam/Repo/QuizTeamRepo';
import { QuizPlayerRepo } from '../Repo/QuizPlayerRepo';
import { QuizTeam } from '../../../interfaces/QuizTeam/QuizTeam';
import { QuizUser } from '../../../interfaces/QuizUser/QuizUser';
import { QuizUserRepo } from '../../QuizUser/QuizUserRepo/QuizUserRepo';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import DeleteModal from '../../Shared/Delete/DeleteModal';
import { SnackbarSeverityEnum } from '../../Shared/Snackbar/SnackbarSeverityEnum';

const UNASSIGNED_TEAM_ID = -1;

interface QuizDataProps {
  quizId: number;
}

const Players: React.FC<QuizDataProps> = ({ quizId }) => {
  const auth = useAuth();
  const quizTeamRepo = new QuizTeamRepo(auth);
  const quizPlayerRepo = new QuizPlayerRepo(auth);
  const quizUserRepo = new QuizUserRepo(auth);

  const sensors = useSensors(useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor,
      {
        coordinateGetter: sortableKeyboardCoordinates
      }))

  useEffect(() => {
    quizTeamRepo.GetQuizTeams((data: QuizTeamWithQuizPlayers[]) => {
      setQuizTeams(data);
    }, quizId, true, true);

    quizUserRepo.GetQuizUsersNotAttached((data: QuizUser[]) => {
      setQuizUsers(data);
    }, quizId);

  }, [quizId, auth]);

  const [quizUsers, setQuizUsers] = useState<QuizUser[]>([]);
  const [selectedQuizUsers, setSelectedQuizUsers] = useState<QuizUser[]>([]);
  const [inviteEmail, setInviteEmail] = useState<string>();
  const [guestName, setGuestName] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [quizTeams, setQuizTeams] = useState<QuizTeamWithQuizPlayers[]>([]);
  const [activeQuizPlayerId, setActiveQuizPlayerId] = useState<UniqueIdentifier>();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>(SnackbarSeverityEnum.Warning);


  function findQuizPlayer(playerQuizId: UniqueIdentifier): QuizPlayer {
    for (const team of quizTeams) {
      for (const playerQuiz of team.quizPlayers) {
        if (playerQuiz.id == playerQuizId) {
          return playerQuiz;
        }
      }
    }

    return {
      id: 0,
      firstName: '',
      lastName: 'Player',
      userId: null,
      guestName: '',
      email: '',
      playerQuizStatus: QuizPlayerStatus.GUEST
    } as QuizPlayer;
  }

  function replacePlayerToTeam(quizPlayerId: number, fromQuizTeamId: number, toQuizTeamId: number) {

    if (toQuizTeamId == UNASSIGNED_TEAM_ID) {
      quizPlayerRepo.MoveQuizPlayerToUnassigned(() => {
        setQuizTeams((prevQuizTeams: QuizTeamWithQuizPlayers[]) => {
          const fromQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == fromQuizTeamId);
          const toQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == toQuizTeamId);
          const quizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers.findIndex((quizPlayer) => quizPlayer.id == quizPlayerId);
          const movedPuizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers[quizPlayerIndex];

          if (fromQuizTeamIndex == UNASSIGNED_TEAM_ID ||
            toQuizTeamIndex == UNASSIGNED_TEAM_ID ||
            quizPlayerIndex == UNASSIGNED_TEAM_ID ||
            !movedPuizPlayerIndex) {
            return prevQuizTeams;
          }


          //TODO make this in a stateHelper, so it does not clutter the component itself 
          const updatedQuizTeams = [...prevQuizTeams];

          updatedQuizTeams[toQuizTeamIndex] = {
            ...updatedQuizTeams[toQuizTeamIndex],
            quizPlayers: [...updatedQuizTeams[toQuizTeamIndex].quizPlayers, movedPuizPlayerIndex],
          };

          updatedQuizTeams[fromQuizTeamIndex] = {
            ...updatedQuizTeams[fromQuizTeamIndex],
            quizPlayers: updatedQuizTeams[fromQuizTeamIndex].quizPlayers.filter((quizPlayer) => quizPlayer.id != quizPlayerId),
          };

          return updatedQuizTeams;
        });
      }, quizId, quizPlayerId);
    }
    else {
      quizPlayerRepo.MoveQuizPlayerToQuizTeam(() => {
        setQuizTeams((prevQuizTeams: QuizTeamWithQuizPlayers[]) => {
          const fromQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == fromQuizTeamId);
          const toQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == toQuizTeamId);
          const quizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers.findIndex((quizPlayer) => quizPlayer.id == quizPlayerId);
          const movedPuizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers[quizPlayerIndex];

          if (fromQuizTeamIndex == UNASSIGNED_TEAM_ID ||
            toQuizTeamIndex == UNASSIGNED_TEAM_ID ||
            quizPlayerIndex == UNASSIGNED_TEAM_ID ||
            !movedPuizPlayerIndex) {
            return prevQuizTeams;
          }

          // Create a new array reference
          const updatedQuizTeams = [...prevQuizTeams];

          // Add the player to the destination team
          updatedQuizTeams[toQuizTeamIndex] = {
            ...updatedQuizTeams[toQuizTeamIndex],
            quizPlayers: [...updatedQuizTeams[toQuizTeamIndex].quizPlayers, movedPuizPlayerIndex],
          };

          // Remove the player from the source team
          updatedQuizTeams[fromQuizTeamIndex] = {
            ...updatedQuizTeams[fromQuizTeamIndex],
            quizPlayers: updatedQuizTeams[fromQuizTeamIndex].quizPlayers.filter((quizPlayer) => quizPlayer.id != quizPlayerId),
          };

          return updatedQuizTeams;
        });
      }, quizId, quizPlayerId, toQuizTeamId);
    }
  }

  function addQuizUserToMultiSelect(playerQuiz: QuizPlayer) {
    if (playerQuiz.id == null) {
      return;
    }

    const quizUser = {
      id: playerQuiz.userId,
      email: playerQuiz.email,
      firstName: playerQuiz.firstName,
      lastName: playerQuiz.lastName
    } as QuizUser;

    setQuizUsers([...quizUsers, quizUser])
  }

  function removePlayerFromQuizTeam(playerQuizId: number, quizTeamId: number) {
    const quizPlayer = quizTeams.filter((quizTeam) => quizTeam.id == quizTeamId)[0].quizPlayers
      .filter((quizPlayers) => quizPlayers.id == playerQuizId)[0];

    if (quizPlayer.userId) {
      addQuizUserToMultiSelect(quizPlayer);
    }

    quizPlayerRepo.RemoveQuizPlayer(() => {
      setQuizTeams((prevQuizTeams) => {
        const quizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id === quizTeamId);

        if (quizTeamIndex === UNASSIGNED_TEAM_ID) {
          return prevQuizTeams;
        }

        const updatedQuizTeam = {
          ...prevQuizTeams[quizTeamIndex],
          quizPlayers: prevQuizTeams[quizTeamIndex].quizPlayers.filter(
            (quizPlayer) => quizPlayer.id !== playerQuizId
          ),
        };

        const updatedQuizTeams = [...prevQuizTeams];
        updatedQuizTeams[quizTeamIndex] = updatedQuizTeam;

        return updatedQuizTeams;
      });
    }, quizId, playerQuizId);
  }

  function addPlayerToUnassignedTeam(quizPlayer: QuizPlayer) {
    const teamIndex = quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);

    const updatedTeam = {
      ...quizTeams[teamIndex],
      quizPlayers: [...quizTeams[teamIndex].quizPlayers, quizPlayer],
    } as QuizTeamWithQuizPlayers;

    setQuizTeams(prevTeams => {
      const updatedTeams = [...prevTeams];
      updatedTeams[teamIndex] = updatedTeam;
      return updatedTeams;
    });
  }

  function addPlayersToUnassignedTeam(quizPlayers: QuizPlayer[]) {
    const teamIndex = quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);

    const updatedTeam = {
      ...quizTeams[teamIndex],
      quizPlayers: [...quizTeams[teamIndex].quizPlayers, ...quizPlayers],
    } as QuizTeamWithQuizPlayers;

    setQuizTeams(prevTeams => {
      const updatedTeams = [...prevTeams];
      updatedTeams[teamIndex] = updatedTeam;
      return updatedTeams;
    });
  }


  function onAddquizUsersMultiselectClick() {
    const selectedExistingPlayerIds = selectedQuizUsers.map(quizUser => quizUser.id);
    const remainingquizUsersInList = quizUsers.filter(player => !selectedExistingPlayerIds.includes(player.id));
    setQuizUsers(remainingquizUsersInList);
    setSelectedQuizUsers([]);

    let newQuizPlayers = [];

    for (let i = 0; i < selectedQuizUsers.length; i++) {
      let player = selectedQuizUsers[i];

      var createQuizPlayer = {
        userId: player.id,
        quizPlayerKind: QuizPlayerKind.EXISTING
      } as CreateQuizPlayer

      quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
        newQuizPlayers.push(quizPlayer);

        if (newQuizPlayers.length === selectedQuizUsers.length) {
          addPlayersToUnassignedTeam(newQuizPlayers);
        }
      },
        quizId,
        createQuizPlayer);
    }
  }

  function GetQuizUserByEmail(email: string) {
    return quizUsers.find(x => x.email.toUpperCase() == email.toUpperCase());
  }

  function GetQuizPlayerFromQuizTeams(email: string) {
    const teamWithPlayer = quizTeams.find(team =>
      team.quizPlayers.some(playerQuiz =>
        playerQuiz && playerQuiz.email && playerQuiz.email.toUpperCase() === email.toUpperCase()
      )
    );

    const playerQuiz = teamWithPlayer
      ? teamWithPlayer.quizPlayers.find(playerQuiz =>
        playerQuiz && playerQuiz.email && playerQuiz.email.toUpperCase() === email.toUpperCase()
      )
      : null;

    return playerQuiz;
  }

  function onSendInviteClick() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!inviteEmail || !emailRegex.test(inviteEmail)) {
      const errorMessage = 'Ongeldig emailadres.';
      setError(errorMessage);
      return;
    }

    setError(null);

    var quizUser = GetQuizUserByEmail(inviteEmail);
    if (quizUser) {
      setSnackbarMessage("Speler bestaat al en werd toegevoegd aan de niet toegewezen lijst.\n(Geen Mail verstuurd)");
      setShowSnackbar(true);
      setSnackbarSeverity(SnackbarSeverityEnum.Warning);
      setQuizUsers(quizUsers.filter(qu => qu.id !== quizUser.id));

      createQuizPlayer(
        {
          userId: quizUser.id,
          quizPlayerKind: QuizPlayerKind.EXISTING
        } as CreateQuizPlayer)
      setInviteEmail('');
      return;
    }

    var quizPlayer = GetQuizPlayerFromQuizTeams(inviteEmail);
    if (quizPlayer) {
      setSnackbarMessage("Speler is al toegevoegd aan de quiz.");
      setSnackbarSeverity(SnackbarSeverityEnum.Error);
      setShowSnackbar(true);
      return;
    }

    createQuizPlayer(
      {
        invitationMail: inviteEmail,
        quizPlayerKind: QuizPlayerKind.INVITATION
      } as CreateQuizPlayer)

    setInviteEmail('');
    setSnackbarSeverity(SnackbarSeverityEnum.Success);
    setSnackbarMessage(`Er is een mail verstuurd naar ${inviteEmail}.`)
    setShowSnackbar(true)
  }

  function onAddGuestClick() {
    if (guestName) {
      quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
        addPlayerToUnassignedTeam(quizPlayer);
        setGuestName('');
      },
        quizId,
        {
          guestName: guestName,
          quizPlayerKind: QuizPlayerKind.GUEST
        } as CreateQuizPlayer);
    }
  }

  function createQuizPlayer(createQuizPlayer: CreateQuizPlayer) {
    quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {

      addPlayerToUnassignedTeam(quizPlayer);
    },
      quizId,
      createQuizPlayer);
  }

  function changeQuizTeamName(quizTeamId: number, newQuizTeamName: string) {
    quizTeamRepo.UpdateQuizTeam(() => {
      let quizTeamToChangeIndex = quizTeams.findIndex(x => x.id == quizTeamId);

      if (quizTeamToChangeIndex !== -1) {
        const quizTeamToChange = quizTeams[quizTeamToChangeIndex];
        quizTeamToChange.name = newQuizTeamName;

        const quizTeamsWithChangedTeamName = [...quizTeams];
        quizTeamsWithChangedTeamName.splice(quizTeamToChangeIndex, 1, quizTeamToChange)

        setQuizTeams(quizTeamsWithChangedTeamName);
      }

    }, quizId, quizTeamId, { QuizTeamName: newQuizTeamName } as updateQuizTeam);
  }

  function onClickAddTeam() {
    quizTeamRepo.CreateQuizTeam((quizTeam: QuizTeam) => {
      const newQuizTeam: QuizTeamWithQuizPlayers = {
        id: quizTeam.id,
        teamId: null,
        isGuestTeam: true,
        name: "Team Nieuw",
        quizPlayers: [],
        focusOnQuizTeam: true
      };

      setQuizTeams([...quizTeams, newQuizTeam]);
    },
      quizId,
      {
        QuizTeamName: "Team Nieuw",
        Sequence: 0
      } as createQuizTeam);
  }

  const handleDragStart = async (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;

    setActiveQuizPlayerId(id);
  }

  const handleDragOver = async (event: DragOverEvent) => {
    const { active, over } = event;
    const { id: activePlayerId, data: activeData } = active as Active;
    const { id: overItemId, data: overData } = over as Over;

    let activeQuizTeamId = activeData.current?.sortable.containerId;
    let overQuizTeamId;

    if (overData.current) {
      overQuizTeamId = overData.current.sortable.containerId;
    }
    else {
      overQuizTeamId = overItemId;
    }

    if (activeQuizTeamId == overQuizTeamId) {
      return;
    }

    replacePlayerToTeam(parseInt(activePlayerId.toString()), activeQuizTeamId, overQuizTeamId);
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    setActiveQuizPlayerId(undefined);
  }

  const onPlayerDelete = (playerQuiz: QuizPlayer, quizTeamId: number) => {
    removePlayerFromQuizTeam(playerQuiz.id, quizTeamId);
  }

  const onTeamDelete = (quizTeamToDelete: QuizTeamWithQuizPlayers) => {
    quizTeamRepo.RemoveQuizTeam(() => {
      const quizTeamsWithoutDeletedQuizTeam = quizTeams.filter((quizTeam) => quizTeam.id != quizTeamToDelete.id);
      const defaultQuizTeam = quizTeamsWithoutDeletedQuizTeam.find((quizTeam) => quizTeam.id == UNASSIGNED_TEAM_ID);

      if (defaultQuizTeam) {
        defaultQuizTeam.quizPlayers.push(...quizTeamToDelete.quizPlayers);
      }

      setQuizTeams(quizTeamsWithoutDeletedQuizTeam)
    }, quizId, quizTeamToDelete.id)
  }

  const handleAutocompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: QuizUser[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<QuizUser>
  ) => {
    setSelectedQuizUsers(value);
  };

  return (
    <>
      <div id="player-container">
        <div id="add-player-container">
          <div id="add-existing-player-container">
            <div style={{ flex: 1 }}>
              {<Autocomplete
                multiple
                fullWidth
                options={quizUsers}
                key={quizUsers.toString()}
                disableCloseOnSelect
                getOptionLabel={(player) => player != null ? `${player.firstName} ${player.lastName}` : ``}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.firstName} {option.lastName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Bestaande spelers" placeholder="Spelers" />
                )}
                onChange={handleAutocompleteChange}
              />}
            </div>
            <Button variant="contained" style={{ marginLeft: "15px" }} onClick={onAddquizUsersMultiselectClick}>
              <AddIcon />
            </Button>
          </div>

          <div id="invite-player-container">
            <div style={{ flex: 1 }}>
              <TextField
                label="Email"
                variant="outlined"
                value={inviteEmail}
                fullWidth
                onChange={(e) => setInviteEmail(e.target.value)}
              />
            </div>
            <Button variant="contained" style={{ marginLeft: "15px" }} onClick={onSendInviteClick}>
              <SendIcon />
            </Button>
          </div>

          {error && (
            <div style={{ color: 'red', marginTop: '8px' }}>
              {error}
            </div>
          )}

          <div id="add-guest-container">
            <div style={{ flex: 1 }}>
              <TextField
                label="gast"
                variant="outlined"
                value={guestName}
                fullWidth
                onChange={(e) => setGuestName(e.target.value)}
              />
            </div>
            <Button variant="contained" style={{ marginLeft: "15px" }} onClick={onAddGuestClick}>
              <AddIcon />
            </Button>
          </div>
        </div>
        <hr />

        <div id="players-teams-container">
          {quizTeams.length > 0
            && (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
              >
                <div id="player-list">
                  <SortableTeam key={-1} quizTeam={quizTeams.filter((quizTeam) => quizTeam.id == -1)[0]}
                    onPlayerDelete={onPlayerDelete} quizId={quizId} onTeamDelete={() => { }} isEditingName={false}
                    handleUpdateQuizTeamName={() => { }} />
                </div>


                <div id="team-list">
                  {
                    quizTeams.filter(quizTeam => quizTeam.id != -1)
                      .map((quizTeam) => (
                        <>
                          <SortableTeam key={quizTeam.id} quizTeam={quizTeam} onPlayerDelete={onPlayerDelete}
                            onTeamDelete={onTeamDelete} quizId={quizId} isEditingName={quizTeam.focusOnQuizTeam ?? false}
                            handleUpdateQuizTeamName={changeQuizTeamName} />
                        </>
                      ))

                  }
                  <div id="add-team-button" onClick={onClickAddTeam}>Voeg team toe</div>
                </div>
                <DragOverlay>
                  {activeQuizPlayerId && (
                    <div
                      style={{
                        position: 'fixed',
                        pointerEvents: 'none',
                        zIndex: 1000,
                        // Add any additional styles you need
                      }}
                    >
                      {/* Render the dragged player here */}
                      <SortablePlayer
                        onHoverPlayer={() => { }}
                        onPlayerDelete={() => { }}
                        dragOverLay={true}
                        playerQuiz={findQuizPlayer(activeQuizPlayerId)}
                      />
                    </div>
                  )}
                </DragOverlay>
              </DndContext>
            )}

        </div>
      </div>

      <SnackbarNotification
        textToDisplay={snackbarMessage}
        setShouldOpenSnackbar={setShowSnackbar}
        shouldOpenSnackbar={showSnackbar}
        horizontalLocation='center'
        verticalLocation='top'
        severity={snackbarSeverity}
      >
      </SnackbarNotification>
    </>
  );
};

export default Players;