import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import '../Style/SendEmailQuizPlayers.scss'
import { SendEmailQuizPlayersModalProps } from './SendEmailQuizPlayersModalProps';
import CloseIcon from '@mui/icons-material/Close';

  const SendEmailQuizPlayersModal: React.FC<SendEmailQuizPlayersModalProps> = ({ open, onClose, quizPlayers, handleSendMail }) => {
    const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);

        useEffect(() => {
            const initialSelectedPlayers = quizPlayers.filter(quizPlayer => !quizPlayer.isInvited)
                                    .map(quizPlayer => quizPlayer.userId);
            setSelectedPlayers(initialSelectedPlayers)
        }, quizPlayers);

        const handleSendEmail = () => {
          handleSendMail(selectedPlayers);
        };

        const handleCheckboxChange = (userId) => {
          setSelectedPlayers(prevSelected =>
            prevSelected.includes(userId)
              ? prevSelected.filter(id => id !== userId)
              : [...prevSelected, userId]
          );
        };
    
          const handleClose = () => {
            onClose();
          }

    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>Spelers uitnodigen</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="quiz-player-list-container">
            <ul className="player-list">
                  {quizPlayers.map(quizPlayer => (
                    <li key={quizPlayer.userId} className="player-item">
                      <input
                        type="checkbox"
                        id={`player-${quizPlayer.id}`}
                        checked={!selectedPlayers.includes(quizPlayer.userId)}
                        onChange={() => handleCheckboxChange(quizPlayer.userId)}
                      />
                <label htmlFor={`player-${quizPlayer.id}`} className="player-name">
                  {quizPlayer.quizPlayerName} 
                </label>
                <div className='player-email'>{quizPlayer.email}</div>
              </li>
            ))}
          </ul>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendEmail}>Verstuur email</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default SendEmailQuizPlayersModal;