import { Round } from "../../../interfaces/Quiz/Round";
import { CreateRound } from "../../../interfaces/Round/CreateRound";
import { RoundSequence } from "../../../interfaces/Round/RoundSequence";
import { RoundUpdate } from "../../../interfaces/Round/UpdateRound";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";

export class RoundRepo {

  private readonly apiClient:AxiosInstance;
  
  constructor(auth:AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }
  
  async GetRoundById(callback: any, id: number): Promise<void> {
    await this.apiClient.get(`/round/${id}`)
      .then((res: any) => {
        let data = res.data as Round;
        callback(data);
      })
  }

  async GetAllRoundsForQuiz(callback: any, quizId: number): Promise<void> {
    await this.apiClient.get(`/quiz/${quizId}/round`)
      .then((res: any) => {
        let data = res.data as Round[];
        callback(data);
      })
  }

  async UpdateRound(callback: any, updateRoundDto: RoundUpdate, quizId: number): Promise<void> {
    await this.apiClient.put(`/quiz/${quizId}/round`, updateRoundDto)
      .then((res:any) => callback(res.data as Round));
  }

  async DeleteRound(callback: any, quizId: number, id: number): Promise<void> {
    await this.apiClient.delete(`/quiz/${quizId}/round/${id}`)
      .then(() => callback());
  }


  async CreateRoundAsync(callback: any, createRound: CreateRound): Promise<void> {
    await this.apiClient.post(`/quiz/${createRound.quizId}/round`, createRound)
      .then((res: any) => {
        let round = res.data as Round;
        callback(round);
      })
  }

  async CalculateRoundSequencesAsync(RoundSequence: RoundSequence, quizId: number): Promise<void> {
    try {
      const response = await this.apiClient.post(`/quiz/${quizId}/round/sequence`, RoundSequence);
    }
    catch (error) {
      throw error
    }
  }
}