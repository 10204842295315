import { FC } from 'react';
import "../../Shared/Misc/GeneralStyling.scss";
import "../../Shared/Header/Header.scss";
import "../../Shared/Form/Formlayout.scss";
import "../Style/QuizStyle.scss"
import Header from '../../Shared/Header/Header';
import AddQuiz from '../Components/AddQuiz';

const AddQuizView: FC = () => {
  return (
    <>
      <Header></Header>
      <main className='formContainer'>
        <div className='add-quiz-container'>
          <AddQuiz/>
        </div>
      </main>
    </>
  );
};

export default AddQuizView;
