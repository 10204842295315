import { FC, useEffect } from 'react';
import AddOrEditQuiz from '../Shared/AddOrEditQuiz';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const AddQuiz: FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);

    useEffect(() => {

    }, [auth])

    const saveQuizData = async (data: SaveQuizData) => {
        data.quizOwnerEmail = "Invaild.Email@gmail.com";// FCO => Get user from auth
        
        await quizRepo.createQuizAsync((quizId: number) => {
            navigate(`/quiz/edit/${quizId}`)
        }
            , (errorData: Map<string, string[]>) => { console.log("ERROR CONVERER COMES HERE") },
            data);
    }

    return (
        <>
            <AddOrEditQuiz
                showQuizDataTab={true}
                showRoundsTab={false}
                showPlayersTab={false}
                showScoresTab={false}
                quizId={null}
                saveQuizData={saveQuizData}
                initialSelectedTabIndex={0}
            />
        </>
    );
};

export default AddQuiz;