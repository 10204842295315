import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useNavigate } from "react-router-dom";

export const ProtectedRoute = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    
    useEffect(() => {
        if (!auth.isAuthenticated) {
            navigate("/login");
          }
      }, [auth])
   
    return (
        <Outlet />
    );
};