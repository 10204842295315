import { AuthContextProps } from "react-oidc-context";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { QuizUser } from "../../../interfaces/QuizUser/QuizUser";

export class QuizUserRepo {
    private readonly apiClient: AxiosInstance;

    constructor(auth: AuthContextProps) {
        this.apiClient = getApiClient(auth);
    }

    async GetQuizUsersNotAttached(callback: (data: QuizUser[]) => void, quizId: number) {
        await this.apiClient.get(`/quiz/${quizId}/user/NotAttached`)
          .then((res: any) => {
            let data = res.data as QuizUser[];
            callback(data);
          });
      }
}