import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, InputAdornment, CircularProgress } from '@mui/material';
import { RoundRepo } from '../../Round/Repo/RoundRepo';
import { Round } from '../../../interfaces/Round/Round';
import { TeamRoundPoint } from '../../../interfaces/Quiz/TeamRoundPoint';
import { useAuth } from 'react-oidc-context';
import { QuizTeam } from '../../../interfaces/QuizTeam/QuizTeam';
import { QuizTeamRepo } from '../../QuizTeam/Repo/QuizTeamRepo';
import { QuizTeamWithQuizPlayers } from '../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers';
import { QuizRepo } from '../../Quiz/Repo/QuizRepo';
import { ScoresRepo } from '../Repo/ScoresRepo';

interface QuizDataProps {
  quizId: number
}

const Scores: React.FC<QuizDataProps> = ({ quizId }) => {
  const auth = useAuth();
  const quizTeamRepo = new QuizTeamRepo(auth);
  const roundRepo = new RoundRepo(auth);
  const quizRepo = new QuizRepo(auth);
  const scoreRepo = new ScoresRepo(auth);

  const [rounds, setRounds] = useState<Round[]>([]);
  const [quizTeams, setQuizTeams] = useState<QuizTeam[]>([]);
  const [teamRoundPoints, setTeamRoundPoints] = useState<TeamRoundPoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalQuizScore, setTotalQuizScore] = useState<number>(0)

  useEffect(() => {
    if (quizId) {
      setLoading(true);
      Promise.all([
        roundRepo.GetAllRoundsForQuiz((data: Round[]) => {
          setRounds(data)
          setTotalQuizScore(data.reduce((total, round) => total + round.maxPoints, 0));
        }, quizId),
        quizTeamRepo.GetQuizTeams((data: QuizTeamWithQuizPlayers[]) => setQuizTeams(data), quizId, false, false),
        quizRepo.GetScores((data: TeamRoundPoint[]) => setTeamRoundPoints(data), quizId)
      ]).finally(() => 
      {        
        setLoading(false)
      });
    }
  }, [quizId, auth]);

  function updateScroresTab(quizTeamId: number, roundId: number, updatedPoints: string){
    const teamRoundPointsWithUpdatedPoints = teamRoundPoints.map(team => {
      if (team.quizTeamId === quizTeamId && team.roundId === roundId) 
      {
          return { ...team, points: updatedPoints };
      }
      return team;
    });

    setTeamRoundPoints(teamRoundPointsWithUpdatedPoints);
  }

  function handleScoreUpdateDatabase(quizTeamId: number, roundId: number)
  {
    var point = GetPoint(quizTeamId, roundId);
    var isValidPoint = !isNaN(parseFloat(point));

    scoreRepo.UpdateQuizTeamRoundScore(() => {
      }, quizId, quizTeamId, roundId, isValidPoint ? parseFloat(point) : 0);
  }

  const handleScoreChange = (quizTeamId: number, roundId: number, points: string) => {
    points = points.replace(".", ",").replace(/[^0-9,]/g, "");

    if(isNaN(parseFloat(points)) && !points.startsWith(",")){
      updateScroresTab(quizTeamId, roundId, "")
      return;
    }

    updateScroresTab(quizTeamId, roundId, points);
  };

  const GetPoint = (quizTeamId: number, roundId: number) => {
    const result = teamRoundPoints.filter((trp) => trp.quizTeamId === quizTeamId && trp.roundId === roundId);
    return result.length > 0 ? result[0].points : "0";
  };

  const GetTotalPoints = (quizTeamId: number) => {
    return teamRoundPoints
      .filter((trp) => trp.quizTeamId === quizTeamId)
      .reduce((total, trp) => total + 
                                (isNaN(parseFloat(trp.points.toString().replace(",", "."))) ? 0 : parseFloat(trp.points.toString().replace(",", "."))),
              0);
  };

  const stickyStyles: React.CSSProperties = {
    position: 'sticky',
    background: '#fff',
    zIndex: 2,
  };

  const teamNameStyles: React.CSSProperties = {
    ...stickyStyles,
    left: 0,
    zIndex: 3,
  };

  const totalScoreStyles: React.CSSProperties = {
    ...stickyStyles,
    right: 0,
    zIndex: 3,
  };

  const headerRowStyles: React.CSSProperties = {
    ...stickyStyles,
    top: 0,
    zIndex: 4,
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper} style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow style={headerRowStyles}>
            <TableCell style={{ ...teamNameStyles }}>
              {/* Empty Cell for the sticky header */}
            </TableCell>
            {rounds.map((round) => (
              <TableCell key={round.id} style={{ textAlign: 'center', minWidth: '50px' }}>
                <Typography variant="h6" component="div" fontWeight="bold">
                  {round.name}
                </Typography>
              </TableCell>
            ))}
            <TableCell style={{ ...totalScoreStyles, textAlign: 'center', minWidth: '50px' }}>
              <Typography variant="h6" component="div" fontWeight="bold">
                Totaal
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {quizTeams
    .filter((quizTeam) => quizTeam && quizTeam.id)
    .map((quizTeam, index) => {
      const backgroundColor = index % 2 === 0 ? '#f5f5f5' : '#ffffff';

      return (
        <TableRow key={quizTeam.id} style={{ backgroundColor }}>
          <TableCell
            style={{
              ...teamNameStyles,
              textAlign: 'center',
              minWidth: '175px',
              backgroundColor, 
            }}
          >
            <Typography variant="h6" component="div" fontWeight="bold">
              {quizTeam.name}
            </Typography>
          </TableCell>
          {rounds.map((round) => (
            <TableCell key={`${quizTeam.id}-${round.id}`} className="scoreTable">
               <TextField
                className="scoreInputField"
                type="text"
                value={GetPoint(quizTeam.id, round.id).toString()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment disableTypography position="end">
                      / {round.maxPoints}
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 1,
                  },
                }}
                onChange={(e) => handleScoreChange(quizTeam.id, round.id, e.target.value)}
                onBlur={(e) => handleScoreUpdateDatabase(quizTeam.id, round.id)}
                onFocus={(e) => {
                  const input = e.target as HTMLInputElement;
                  if (input.selectionStart === input.selectionEnd) {
                    // If the user navigated using Tab, select all text
                    input.select();
                  }
                }}
              />
            </TableCell>
          ))}
          <TableCell
            style={{
              ...totalScoreStyles,
              textAlign: 'center',
              minWidth: '150px',
              backgroundColor,
            }}
          >
          <Typography 
            variant="h6" 
            component="div" 
            fontWeight="bold" 
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <span style={{ marginRight: '4px' }}>{GetTotalPoints(quizTeam.id)}</span>
            <span style={{ color: 'gray' }}>/{totalQuizScore}</span>
          </Typography>
          </TableCell>
        </TableRow>
      );
    })}
</TableBody>


      </Table>
    </TableContainer>
  );
};

export default Scores;