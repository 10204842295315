import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, IconButton, Typography, Modal, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { useAuth } from 'react-oidc-context';
import { QuizRepo } from '../../Quiz/Repo/QuizRepo';
import { QuestionsFromRound } from '../../../interfaces/Quiz/QuestionsFromRound';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { Round } from '../../../interfaces/Quiz/Round';
import { Question } from '../../../interfaces/Quiz/Question';
import { KindOfROundChoices } from '../../../interfaces/Round/KindOfRoundChoices';
import DeleteModal from '../../Shared/Delete/DeleteModal';

interface SortableQuestionProps {
  initialQuestion: QuestionsFromRound;
  onDeleteQuestion: (questionId: number) => void;
  quiz: Quiz;
  descriptionRef: React.RefObject<HTMLInputElement | null>;
  selectedRound: Round;
  onQuestionChange: (updatedQuestion: UpdateQuestion) => void
}

function SortableQuestion({
  initialQuestion,
  onDeleteQuestion,
  quiz,
  descriptionRef,
  selectedRound,
  onQuestionChange
}: SortableQuestionProps) {
  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [question, setQuestion] = useState<QuestionsFromRound>(initialQuestion);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    setQuestion(initialQuestion);
  }, [quiz]);


  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = event.target.value;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      description: newDescription,
    }));
  };

  const onChangeAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = event.target.value;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      answer: newAnswer,
    }));
  };

  const onBlurQuestionAnswer = () => {
    handleSaveChanges();
  };

  const onBlurQuestionDescription = () => {
    handleSaveChanges();
  };

  const generateQuestionBasedOnKindOfRound = () => {
    if (selectedRound.kindOfRound === KindOfROundChoices.PHOTO || selectedRound.kindOfRound === KindOfROundChoices.VIDEO) {
      return generatePhotoQuestionInputFields();
    }
    else if (selectedRound.kindOfRound === KindOfROundChoices.MULTIPLE_CHOICE) {
      return <p>Louis wordt boos</p>
    }
    else {
      return generateNormalQuestionInputFields();
    }
  }
  //TODO (FCO) => Every kind of round should have a diffrent component in a seperate file. Under here is a example that is the same file, it should be in a seperate one for
  // Normal (As is)
  // Photo (with a dropfield for a image (also extra input field for question and Photo URL) & extra API call to our API for pushing image to dropbox)
  // Video (with a dropfield for a video (also extra input field for question and Video URL)& extra API call to our API for pushing image to dropbox)
  // Multiple choice (Add question and optino to add mulitile answers where one or more are correct, both should be avialble)
  const generateNormalQuestionInputFields = () => {
    return <>
      <TextField
        className='question-input-element'
        label="Vraag"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={question.description}
        onChange={handleDescriptionChange}
        onBlur={onBlurQuestionDescription}
        inputRef={descriptionRef}
      />
      <TextField
        className='question-input-element question-input-element-answer'
        label="Antwoord"
        variant="outlined"
        fullWidth
        value={question.answer}
        onChange={onChangeAnswer}
        onBlur={onBlurQuestionAnswer}
      />
    </>
  }

  //TODO (FCO) => Again ths should be in a seperate file's. 1 for photo and 1 for video
  const generatePhotoQuestionInputFields = () => {
    return <>
      <TextField
        className='question-input-element'
        label="Vraag"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={question.description}
        onChange={handleDescriptionChange}
        onBlur={onBlurQuestionDescription}
        inputRef={descriptionRef}
      />
      <TextField
        className='question-input-element question-input-element-answer'
        label="Antwoord - foto/Video"
        variant="outlined"
        fullWidth
        value={question.answer}
        onChange={onChangeAnswer}
        onBlur={onBlurQuestionAnswer}
      />
    </>
  }

  const handleSaveChanges = async () => {
    const updateQuestion: UpdateQuestion = {
      id: question.id,
      description: question.description ?? "",
      answer: question.answer ?? "",
    };
    try {
      await quizRepo.UpdateQuestion(() => { onQuestionChange(updateQuestion); }, updateQuestion);
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
      className="question-list-element"
      ref={setNodeRef}
      style={styles}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Card variant="outlined" className="question-card">
        <CardContent>
          <div className="">
            <div className="round-container-left">
              {/* <Typography variant="h6">{question.sequence}</Typography> */}
            </div>
            <div className="round-container-right">
              <IconButton
                className={`round-container-button ${isHovered ? '' : "round-container-button-hidden"}`}
                aria-label="Delete"
                onClick={() => setShowConfirmationModal(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                className="round-container-drag"
                color="primary"
                aria-label="Drag"
                {...attributes}
                {...listeners}
              >
                <DragIndicatorIcon />
              </IconButton>
            </div>
          </div>

          {generateQuestionBasedOnKindOfRound()}
        </CardContent>
      </Card>
      </div>

      <DeleteModal
        deleteCallback={() => onDeleteQuestion(question.id)}
        setShouldOpenModel={setShowConfirmationModal}
        shouldOpenModal={showConfirmationModal}
        subject='Vraag'
        subjectValue={question.description}>
      </DeleteModal>
    </>
  );
}

export default SortableQuestion;
